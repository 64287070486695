import { defaultTheme, rpxTransformers } from '@xstyled/styled-components'

const colors = {
  ...defaultTheme.colors,
  white: '#FFFFFF',
  whiteAlpha: 'rgba(255, 255, 255, .3)',
  grey: '#434343',
  mdGrey: '#707070',
  ltGrey: '#F0F0F0',
  vltGrey: '#F5F5F5',
  red: '#B41400',
  redAlpha: 'rgba(180, 20, 0, 0.1)',
  redPuzzles: '#F7BABE',
  blue: '#2F80ED',
  blueAlpha: 'rgba(47, 128, 237, 0.2)',
  ltBlue: '#82B4F8',
  divider: '#D4D4D4',
  meta: '#A7A7A7',
  advert: '#F3F3F3',
  advertText: '#999999',
  tab: '#828282',
  menuBot: '#F7F7F7',
  indyPlusAdBord: '#58A4C8',
  indyPlusAdBG: '#E1E9EE',
  orange: '#ED5C35',
  orangeAlpha: 'rgba(237, 92, 53, 0.1)',
  black: '#000000'
}

const fontWeights = {
  regular: '400',
  medium: '500',
  semiBold: '600',
  bold: '700'
}

export const theme = {
  ...defaultTheme,
  transformers: {
    ...rpxTransformers
  },
  states: {
    ...defaultTheme.states,
    lastType: '&:last-of-type',
    after: '&::after'
  },
  fontWeights,
  colors,
  space: {
    px: '1px',
    5: `${5 / 16}rem`,
    6: `${6 / 16}rem`,
    8: `${8 / 16}rem`,
    10: `${10 / 16}rem`,
    15: `${15 / 16}rem`,
    20: `${20 / 16}rem`,
    30: `${30 / 16}rem`,
    50: `${50 / 16}rem`,
    80: `${80 / 16}rem`
  },
  screens: {
    ...defaultTheme.screens,
    sm: 0,
    wAdM: 0,
    tablet: 640,
    md: 1023,
    lg: 1024,
    xl: 1220,
    wAdD: 1316,
    article: 1500
  },
  texts: {
    h1: {
      fontSize: {
        lg: `${28 / 16}rem`,
        sm: `${26 / 16}rem`
      }
    },
    h2: {
      fontSize: {
        lg: `${28 / 16}rem`,
        sm: `${26 / 16}rem`
      }
    },
    h3: {
      fontSize: `${26 / 16}rem`
    },
    h4: {
      fontSize: `${22 / 16}rem`
    },
    h5: {
      fontSize: `${19 / 16}rem`
    },
    h6: {
      fontSize: `${16 / 16}rem`
    },
    xxlarge: {
      fontSize: `${34 / 16}rem`
    },
    xlarge: {
      fontSize: `${20 / 16}rem`
    },
    large: {
      fontSize: `${17 / 16}rem`
    },
    medium: {
      fontSize: `${16 / 16}rem`
    },
    small: {
      fontSize: `${14 / 16}rem`
    },
    xsmall: {
      fontSize: `${12 / 16}rem`
    },
    xxsmall: {
      fontSize: `${10 / 16}rem`
    },
    xxxsmall: {
      fontSize: `${8 / 16}rem`
    },
    meta: {
      fontSize: `${12 / 16}rem`,
      color: colors.meta
    },
    advert: {
      fontSize: `${10 / 16}rem`,
      color: colors.advertText
    },
    button: {
      fontSize: `${14 / 16}rem`
    },
    dropdown: {
      fontSize: `${14 / 16}rem`,
      fontWeight: fontWeights.semiBold
    },
    'menu-head': {
      fontSize: `${64 / 16}rem`,
      color: 'rgba(67, 67, 67, 0.15)',
      fontWeight: fontWeights.semiBold,
      lineHeight: 0.6
    },
    'menu-sub-head': {
      fontSize: `${14 / 16}rem`,
      fontWeight: fontWeights.semiBold,
      textTransform: 'uppercase'
    },
    breadcrumb: {
      fontSize: `${12 / 16}rem`,
      textTransform: 'uppercase',
      color: {
        _: colors.orange,
        hover: colors.orange
      }
    },
    footerH6: {
      fontSize: `${14 / 16}rem`,
      color: 'white',
      fontWeight: fontWeights.semiBold,
      textTransform: 'uppercase',
      fontFamily: '"Work Sans", sans-serif'
    },
    'footer-lnk': {
      fontSize: `${14 / 16}rem`,
      color: {
        _: colors.white,
        hover: colors.ltBlue
      }
    },
    'commercial-nav': {
      fontSize: `${12 / 16}rem`,
      textTransform: 'uppercase',
      color: colors.white,
      opacity: {
        _: 1,
        hover: 0.8
      }
    },
    'brand-badge': {
      fontSize: `${12 / 16}rem`,
      color: colors.blue,
      textTransform: 'uppercase'
    },
    tag: {
      fontSize: `${14 / 16}rem`,
      textTransform: 'uppercase',
      textDecordation: 'none',
      color: {
        _: colors.grey,
        hover: colors.blue
      },
      backgroundColor: {
        _: '#F2F2F2',
        hover: '#F2F2F2',
        before: '#F2F2F2'
      }
    },
    trendLink: {
      fontSize: `${14 / 16}rem`,
      textTransform: 'uppercase',
      color: {
        _: colors.grey,
        hover: colors.red
      }
    },
    trendLinkDarMode: {
      fontSize: `${14 / 16}rem`,
      textTransform: 'uppercase',
      color: {
        _: colors.white,
        hover: colors.red
      }
    },
    trendBadge: {
      fontSize: `${12 / 16}rem`,
      textTransform: 'uppercase',
      color: colors.orange
    },
    opinionHeading: {
      fontSize: {
        lg: `${64 / 16}rem`,
        sm: `${40 / 16}rem`
      }
    }
  },
  drawers: {
    backdrop: {
      backgroundColor: 'rgba(0, 0, 0, 0.55)',
      zIndex: 999
    },
    default: {
      backgroundColor: colors.white,
      zIndex: 999
    },
    content: {
      backgroundColor: colors.white,
      padding: 15
    }
  },
  popovers: {
    content: {
      //
    },
    default: {
      backgroundColor: colors.white,
      zOrder: 10
    },
    title: {
      //
    }
  },
  popups: {
    closeButton: {
      position: 'absolute',
      cursor: 'pointer',
      top: '5px',
      right: '5px'
    },
    default: {
      backgroundColor: colors.ltGrey,
      zIndex: 999
    },
    sizes: {
      horizontal: {
        sm: {
          minWidth: 300,
          maxWidth: 400
        },
        md: {
          width: 375
        },
        lg: {
          width: 375
        }
      },
      vertical: {
        sm: {
          height: 400
        },
        md: {
          height: 550
        },
        lg: {
          height: 680
        }
      }
    }
  },
  carousels: {
    navigation: {
      bullet: {
        active: {
          backgroundColor: colors.ltGrey
        },
        default: {
          opacity: '.2'
        }
      }
    }
  },
  tabs: {
    buttons: {
      selected: {
        fontSize: `${14 / 16}rem`,
        fontFamily: '"PT Serif", serif',
        backgroundColor: colors.white,
        color: colors.grey,
        padding: '8px 10px',
        textTransform: 'uppercase'
      },
      normal: {
        fontSize: `${14 / 16}rem`,
        fontFamily: '"PT Serif", serif',
        backgroundColor: colors.ltGrey,
        color: colors.tab,
        padding: '8px 10px',
        textTransform: 'uppercase'
      },
      hover: {
        color: colors.grey
      }
    }
  },
  accordions: {
    padding: `${10 / 16}rem`,
    wrapper: {
      backgroundColor: 'white',
      border: 'none'
    },
    content: {
      fontSize: `${14 / 16}rem`
    },
    title: {
      padding: '10px 15px',
      color: colors.grey,
      fontSize: `${17 / 16}rem`,
      fontWeight: fontWeights.semiBold,
      fontFamily: '"Work Sans", sans-serif',
      borderRadius: '3px',
      alignItems: 'center',
      justifyContent: 'space-between',
      marginBottom: '5px',
      backgroundColor: colors.vltGrey
    }
  },
  imageWidths: {
    hero: 560,
    featured: 800,
    primary: 240,
    slider: 275,
    normal: 100
  }
}

export default theme
