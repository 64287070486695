import React from 'react'
import { useLocation } from 'react-router-dom'
import { x } from '@xstyled/styled-components'
import StyledLink from '../components/ui/Link'

import cssfxUnderline from '../components/ui/cssfxUnderline'
import Ad from '../components/Ad'
import FORMATS from '../components/AdFormats'

import logoWht from '../../../assets/images/general/iol-wht.svg'
import facebookWht from '../../../assets/images/social-media/facebook-wht.svg'
import youtubeWht from '../../../assets/images/social-media/youtube-wht.svg'
import instagramWht from '../../../assets/images/social-media/instagram-wht.svg'
import rssWht from '../../../assets/images/social-media/rss-wht.svg'
import twitterWht from '../../../assets/images/social-media/x-wht.svg'
import googlePlay from '../../../assets/images/icons/google-play.svg'
import appStore from '../../../assets/images/icons/app-store.svg'
import tiktokGrey from '../../../assets/images/icons/tiktok.svg'

const Sticky = [
  [[1024, 0], []],
  [[640, 0], [[320, 100], [320, 50], [300, 100], [300, 50], 'fluid']],
  [[0, 0], [[320, 100], [320, 50], [300, 100], [300, 50], 'fluid']]
]

const Footer = (props) => {
  const location = useLocation()
  const isArticlePage = !!location.pathname.match(/([0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12})|([0-9]{5,})$/)
  const adPath = `${props.adPath || (isArticlePage ? location.pathname : ((location.pathname === '/' ? '' : location.pathname) + '/homepage'))}`
  const clickSource = 'Footer'
  return (
    <>
      <x.div bg='advert' textAlign='center' display={{ sm: 'flex', xl: 'none' }} visibility={{ sm: 'visible', xl: 'hidden' }} justifyContent='center' alignItems='center' flexDirection='column' position='fixed' bottom='0' w='100%' p={10} zIndex='10'>
        <Ad isConnected={props.isConnected} adPath={adPath} slotId='Mobile-Leaderboard-sticky' targeting={{ 'Mobile-Leaderboard': 'sticky' }} mapping={Sticky} className='advert' {...FORMATS.mobileLeaderboardSticky} />
      </x.div>
      <x.footer w='100%' backgroundColor='grey' display='flex' flexDirection='column' alignItems='center' justifyContent='center' py={20} px={20} pt={50} pb={{ sm: '72', lg: '0' }}>
        <x.div w='100%' maxWidth='1220px' display='grid' gridTemplateColumns={{ sm: 'repeat(2, 50%)', lg: 'repeat(4, calc((100% - 120px) / 4))' }} gap={{ sm: '0', lg: '40' }} alignContent='center' color='white'>
          <x.div position='relative' className='divider-right-footer' p={{ sm: '0 0 20px 0', lg: '0' }} mb={{ sm: '20px', lg: '0' }} borderWidth={{ sm: '0 0 1px 0', lg: '0' }} borderStyle='solid' borderColor='whiteAlpha' gridColumn={{ sm: '1 / span 2', lg: 'unset' }} w='100%'>
            <x.img src={logoWht} w={100} mb={20} alt='IOL' />
            <x.p text='small'>Independent Online, popularly known as IOL, is one of South Africa’s leading news and information websites bringing millions of readers breaking news and updates on Politics, Current Affairs, Business, Lifestyle, Entertainment, Travel, Sport, Motoring and Technology. <StyledLink color='white' to='/about-iol' sessionId={props.sessionId} visitorId={props.visitorId} section={props.section} source={clickSource}><cssfxUnderline.SweepOut hoverBGColor='ltBlue' hoverColor='ltBlue'>Read more</cssfxUnderline.SweepOut></StyledLink></x.p>
            <x.div display='flex' flexDirection='row' mt={20}>
              <x.a href='https://www.facebook.com/IOLnews' target='_blank' mr={10}><img src={facebookWht} alt='Like us on Facebook' /></x.a>
              <x.a href='https://www.twitter.com/IOL' target='_blank' mr={10}><img src={twitterWht} alt='Follow us on Twitter' /></x.a>
              <x.a href='https://www.instagram.com/iolnews' target='_blank' mr={10}><img src={instagramWht} alt='Follow us on Instagram' /></x.a>
              <x.a href='https://www.tiktok.com/@iolnews' target='_blank' mr={10}><img src={tiktokGrey} alt='Follow us on TikTok' /></x.a>
              <x.a href='https://www.youtube.com/channel/UCeQFd7S6urAw6mm0kS4xuhg' target='_blank' mr={10}><img src={youtubeWht} alt='Subscribe to us on Youtube' /></x.a>
              <x.a href='/rss' mr={10}><img src={rssWht} alt='Subscribe to our RSS feeds' /></x.a>
            </x.div>
          </x.div>
          <x.div position='relative' className='divider-right-footer' text='small' borderWidth={{ sm: '0 1px 0 0', lg: '0' }} borderStyle='solid' borderColor='whiteAlpha' gridColumn={{ sm: '1 / 2', lg: 'unset' }} pr={20} mb={20}>
            <x.h6 text='footerH6'>Sections on IOL</x.h6>
            <x.nav role='navigation' aria-label='Sections on IOL' mt={20}>
              <x.ul>
                <x.li mb={3}><StyledLink color='white' to='/news' text='footer-lnk' sessionId={props.sessionId} visitorId={props.visitorId} section={props.section} source={clickSource}>News</StyledLink></x.li>
                <x.li mb={3}><StyledLink color='white' to='/lifestyle' text='footer-lnk' sessionId={props.sessionId} visitorId={props.visitorId} section={props.section} source={clickSource}>Lifestyle</StyledLink></x.li>
                <x.li mb={3}><StyledLink color='white' to='/entertainment' text='footer-lnk' sessionId={props.sessionId} visitorId={props.visitorId} section={props.section} source={clickSource}>Entertainment</StyledLink></x.li>
                <x.li mb={3}><StyledLink color='white' to='/travel' text='footer-lnk' sessionId={props.sessionId} visitorId={props.visitorId} section={props.section} source={clickSource}>Travel</StyledLink></x.li>
                <x.li mb={3}><StyledLink color='white' to='/business' text='footer-lnk' sessionId={props.sessionId} visitorId={props.visitorId} section={props.section} source={clickSource}>Business</StyledLink></x.li>
                <x.li mb={3}><StyledLink color='white' to='/property' text='footer-lnk' sessionId={props.sessionId} visitorId={props.visitorId} section={props.section} source={clickSource}>Property</StyledLink></x.li>
                <x.li mb={3}><StyledLink color='white' to='/sport' text='footer-lnk' sessionId={props.sessionId} visitorId={props.visitorId} section={props.section} source={clickSource}>Sport</StyledLink></x.li>
                <x.li mb={3}><StyledLink color='white' to='/motoring' text='footer-lnk' sessionId={props.sessionId} visitorId={props.visitorId} section={props.section} source={clickSource}>Motoring</StyledLink></x.li>
                <x.li mb={3}><StyledLink color='white' to='/opinion' text='footer-lnk' sessionId={props.sessionId} visitorId={props.visitorId} section={props.section} source={clickSource}>Opinion</StyledLink></x.li>
                {/* <x.li mb={3}><StyledLink color='white' to='/video' text='footer-lnk' sessionId={props.sessionId} visitorId={props.visitorId} section={props.section} source={clickSource}>Video</StyledLink></x.li> */}
              </x.ul>
            </x.nav>
          </x.div>
          <x.div position='relative' className='divider-right-footer' text='small' gridColumn={{ sm: '2 / 2', lg: 'unset' }} pl={20} mb={20}>
            <x.h6 text='footerH6'>More on IOL</x.h6>
            <x.nav role='navigation' aria-label='More on IOL' mt={20}>
              <x.ul>
                <x.li mb={3}><StyledLink color='white' to='/about-iol' text='footer-lnk' sessionId={props.sessionId} visitorId={props.visitorId} section={props.section} source={clickSource}>About IOL</StyledLink></x.li>
                <x.li mb={3}><StyledLink color='white' to='/contact-us' text='footer-lnk' sessionId={props.sessionId} visitorId={props.visitorId} section={props.section} source={clickSource}>Contact Us</StyledLink></x.li>
                <x.li mb={3}><StyledLink color='white' to='/news/weather' text='footer-lnk' sessionId={props.sessionId} visitorId={props.visitorId} section={props.section} source={clickSource}>Weather</StyledLink></x.li>
                <x.li mb={3} display={{ lg: 'none' }} visibility={{ lg: 'hidden' }}><StyledLink color='white' to='/my-news' text='footer-lnk' sessionId={props.sessionId} visitorId={props.visitorId} section={props.section} source={clickSource}>My News</StyledLink></x.li>
                <x.li mb={3} display={{ lg: 'none' }} visibility={{ lg: 'hidden' }}><StyledLink color='white' to='/my-bookmarks' text='footer-lnk' sessionId={props.sessionId} visitorId={props.visitorId} section={props.section} source={clickSource}>Bookmarks</StyledLink></x.li>
                {/* <x.li mb={3}><StyledLink color='white' to='/newspapers' text='footer-lnk' sessionId={props.sessionId} visitorId={props.visitorId} section={props.section} source={clickSource}>Newspapers Highlights</StyledLink></x.li>
                <x.li mb={3}><StyledLink color='white' to='/newspapers-contacts' text='footer-lnk' sessionId={props.sessionId} visitorId={props.visitorId} section={props.section} source={clickSource}>Newspapers Contacts</StyledLink></x.li> */}
                <x.li mb={3}><StyledLink color='white' to='/my-notifications' text='footer-lnk' sessionId={props.sessionId} visitorId={props.visitorId} section={props.section} source={clickSource}>Manage My Notifications</StyledLink></x.li>
              </x.ul>
            </x.nav>
          </x.div>
          <x.div text='small' p={{ sm: '20px 0 0', lg: '0' }} gridColumn={{ sm: '1 / span 2', lg: 'unset' }} borderWidth={{ sm: '1px 0 0 0', lg: '0' }} borderStyle='solid' borderColor='whiteAlpha' display={{ sm: 'grid', lg: 'unset' }} gridTemplateColumns={{ sm: 'repeat(2, calc((100% - 40px) / 2))', lg: '100%' }} gap={{ sm: '40', lg: '0' }}>
            <x.div>
              <x.h6 text='footerH6'>Subscribe</x.h6>
              <x.nav role='navigation' aria-label='Subscribe' mt={20}>
                <x.ul>
                  {/* <x.li mb={3}><StyledLink color='white' to='/newspapers' text='footer-lnk' sessionId={props.sessionId} visitorId={props.visitorId} section={props.section} source={clickSource}>Independent Media</StyledLink></x.li> */}
                  <x.li><StyledLink color='white' to='/newsletters' text='footer-lnk' sessionId={props.sessionId} visitorId={props.visitorId} section={props.section} source={clickSource}>Email Newsletter</StyledLink></x.li>
                </x.ul>
              </x.nav>
            </x.div>
            <x.div mt={30}>
              <x.a href='https://play.google.com/store/apps/details?id=com.iol.news&hl=en_ZA&gl=US' target='_blank'><x.img src={googlePlay} alt='Get the IOL app on Google Play' /></x.a>
              <x.a href='https://apps.apple.com/za/app/iol-news/id479535200' target='_blank'><x.img src={appStore} alt='Get the IOL app on the App Store' mt={10} /></x.a>
            </x.div>
          </x.div>
        </x.div>
        <x.div w='100%' maxWidth='1220px' mt={20} py={20} display='grid' gridTemplateColumns={{ sm: '100%', lg: 'minmax(min-content, 500px) minmax(min-content, 720px)' }} gap={{ lg: '40' }} alignContent='center' color='white' borderWidth='1px 0 0 0' borderStyle='solid' borderColor='whiteAlpha'>
          <x.div>
            <x.p text='xsmall'>&copy; {new Date().toLocaleDateString('en-ZA', { year: 'numeric' })} Independent Online and affiliated companies. All rights reserved.</x.p>
          </x.div>
          <x.div display='flex' alignContent='center' justifyContent={{ lg: 'space-between' }} mt={{ sm: '10px', lg: '0' }} text='xsmall' spaceX={{ lg: '10' }} flexWrap={{ sm: 'wrap', lg: 'nowrap' }}>
            <StyledLink color='white' to='/press-code' text='footer-lnk' mr={{ sm: '10' }} sessionId={props.sessionId} visitorId={props.visitorId} section={props.section} source={clickSource}>Press Code</StyledLink>
            <StyledLink color='white' to='/privacy-policy' text='footer-lnk' mr={{ sm: '10' }} sessionId={props.sessionId} visitorId={props.visitorId} section={props.section} source={clickSource}>Privacy Policy</StyledLink>
            <StyledLink color='white' to='/terms-and-conditions' text='footer-lnk' mr={{ sm: '10' }} sessionId={props.sessionId} visitorId={props.visitorId} section={props.section} source={clickSource}>Terms &amp; Conditions</StyledLink>
            <StyledLink color='white' to='/advertising' text='footer-lnk' mr={{ sm: '10' }} sessionId={props.sessionId} visitorId={props.visitorId} section={props.section} source={clickSource}>Advertise with us</StyledLink>
            <StyledLink color='white' to='/feedback' text='footer-lnk' mr={{ sm: '10' }} sessionId={props.sessionId} visitorId={props.visitorId} section={props.section} source={clickSource}>Feedback</StyledLink>
            <StyledLink color='white' to='/complaints' text='footer-lnk' sessionId={props.sessionId} visitorId={props.visitorId} section={props.section} source={clickSource}>Complaints Procedure</StyledLink>
          </x.div>
        </x.div>
      </x.footer>
    </>
  )
}

export default Footer
